import './modalNotification.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

const ModalNotification = ({setOpenNotificationModal}) => {
    const handleClick = () => {
        setOpenNotificationModal(false)
    }
  return (
    <div className="modalNotification">
            <div className="modalNotificationContainer">
                <FontAwesomeIcon 
                    icon={faCircleXmark}
                    className="closeModal"
                    onClick={ handleClick }
                />
                
                <div className="modalNotificationTitle">
                    <h3>Reminders</h3>
                </div>
                <div className="modalNotificationForm">
                    <div className="modalNotificationFormColumn">
                        <div className="modalNotificationFormItem">
                            <p>Date Time used for reserving spaces is Philippine Standard Time (PHT)</p>
                        </div>
                        <button className="modalNotificationBtn" onClick={ handleClick }>I understand</button>
                        
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ModalNotification